<!-- 积分记录 -->
<template>
  <div class="total">
    <!-- <p class="myclass-title">
      <span class="title-border"></span>
      <span class="title-text">学分记录 <span v-show="count>0">({{ count }})</span></span>
    </p> -->
    <headers ref="headers" :headname="headName"></headers>
    <el-table
      v-show="count>0"
      :data="tableData"
      style="width: 100%"
      stripe
    >
      <el-table-column
        prop="create_time"
        label="日期"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="type_name"
        label="获取方式"
        width="600"
      >
      </el-table-column>
      <el-table-column
        prop="sumscore"
        label="分数"
      >
      </el-table-column>
    </el-table>
    <div class="feny">
      <el-pagination
        v-show="count>10"
        class="page"
        background
        layout="prev, pager, next"
        :page-size="params.limit"
        :total="count"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
    <div v-show="count===0" class="myclass-table noDataBox">
      <span class="noData">暂无数据</span>
      <img src="@/assets/image/noData.png" alt="">
    </div>
  </div>
</template>

<script>
import { getUserCredit } from '@/api/mine.js'
import headers from '@/components/topTitle/index.vue'
export default {
  name: '',
  components: { headers },

  data() {
    return {
      headName: '学分记录',
      count: 0,
      tableData: [],
      params: {
        page: 1,
        limit: 10,
        uid: ''
      }
    }
  },

  computed: {},

  created() {
    this.pageInit()
    this.getlist()
  },

  mounted() {},

  methods: {
    pageInit() {
      if (this.$store.state.userMsg.uid !== '') {
        this.params.uid = this.$store.state.userMsg.uid
      }
    },
    getlist() {
      getUserCredit(this.params).then(res => {
        this.count = res.result.count
        this.tableData = res.result.list
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    },
    // 分页
    handleCurrentChange(val) {
      //  当前点击页数
      this.params.page = val
      this.getlist()
    }
  }
}

</script>
<style lang='scss' scoped>
.total{
  width: 965px;
  padding: 0 0px 40px;
  background-color: #fff;
}

.myclass-title {
  padding: 26px 60px 12px 12px;
  text-align: left;
  display: flex;
  align-items: center;
  .title-text {
    font-size: 20px;
  }
  .title-border {
    margin-right: 8px;
    display: inline-block;
    width: 4px;
    height: 20px;
    background: #ee2e2e;
  }
}

.feny{
  margin-top: 20px;
}
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
.noDataBox{
  position: relative;
  img{
    display: block;
    margin: 50px auto 0;
  }
  .noData{
    font-size: 18px;
  }
}
</style>
